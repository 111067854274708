<template>
  <view-item title="内部领料">
    <template #operation>
      <en-dropdown @command="operation.add.command">
        <en-button type="primary">创建单据</en-button>
        <template #dropdown>
          <en-dropdown-item command="MRQ">内部领料</en-dropdown-item>
          <en-dropdown-item command="MRQR">内部退料</en-dropdown-item>
        </template>
      </en-dropdown>

      <button-ajax v-if="form.data.id" :disabled="form.disabled || form.loading" :method="operation.submit.click"> 保存 </button-ajax>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="detail" :disabled="form.disabled">编辑内部{{ form.data.type?.message }}</en-dropdown-item>
          <en-dropdown-item command="delete" :disabled="form.disabled">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        :ajax="{ action: 'GET /enocloud/material/requisition' }"
        :props="{ start: 'preparedStartDate', end: 'preparedEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <en-table-column label="领/退料单单号" prop="serialNo"></en-table-column>
        <en-table-column label="状态" prop="status.message"></en-table-column>

        <template #form="{ data }">
          <en-form-item label="领/退料单单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
          <en-form-item label="单据日期">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate"></en-date-picker>
          </en-form-item>
          <en-form-item label="单据类型">
            <select-maintain
              v-model="data.typeCode"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['MTRQTYPE']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="编码">
            <en-input v-model="data.goodsSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="OE号">
            <en-input v-model="data.goodsOem"></en-input>
          </en-form-item>
          <en-form-item label="配件名称">
            <en-input v-model="data.goodsName"></en-input>
          </en-form-item>
          <en-form-item label="仓库">
            <select-maintain
              v-model="data.warehouseId"
              :ajax="{ action: 'GET /enocloud/common/warehouse', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="领/退料人">
            <select-maintain
              v-model="data.pickedById"
              :ajax="{ action: 'GET /enocloud/common/user', params: (params, name) => (params.payload = { name }) }"
              :props="{ label: 'name', value: 'id' }"
              remote
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务类型：内部{{ form.data.type?.message }}</span>
        </div>

        <div class="flex items-center gap-6">
          <span>{{ form.data.preparedBy?.name }}</span>
          <span>{{ form.data.warehouse?.name }}</span>
          <en-tag>{{ form.data.status?.message }}</en-tag>
          <span>{{ form.data.serialNo }}</span>
          <span>{{ formatDate(form.data.preparedDatetime) }}</span>
          <span>{{ form.data.comment }}</span>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.materialRequisitionGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    :ajax="{
                      action: 'GET /enocloud/common/goods',
                      params: (params, value) => (params.payload = { name: value })
                    }"
                    :props="{ label: 'name', value: '', disabled: (option: EnocloudCommonDefinitions['GoodsDto']) => Boolean(form.data.materialRequisitionGoods?.find(item => item.goods?.id === option.id))}"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    class="w-110"
                    :disabled="form.disabled"
                    @change="form.materialRequisitionGoods.operation.change"
                  ></select-maintain>
                </div>
                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic code="MRQIDLFL" :data="form.data.materialRequisitionGoods" :loading="form.loading" :height="height">
                      <template #OPERATION="{ $index }: { $index: number }">
                        <en-button type="primary" link @click="form.materialRequisitionGoods.operation.delete.click($index)">删除</en-button>
                      </template>

                      <template #GOODS_SERIAL_NO="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.serialNo }}
                      </template>

                      <template #GOODS_NAME="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.name }}
                      </template>

                      <template #GOODS_OEM="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.oem }}
                      </template>

                      <template #GOODS_SPECIFICATION_NAME="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        <select-maintain
                          v-model="row.goodsSpecification"
                          :options="
                            row.goodsSpecification?.goods?.specifications?.length
                              ? row.goodsSpecification?.goods?.specifications
                              : row.goods?.specifications
                          "
                          :props="{ label: 'name', value: '' }"
                          value-key="id"
                        ></select-maintain>
                      </template>

                      <template #GOODS_SPECIFICATION_WEIGHT="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ `${row.goodsSpecification?.weight}${row.goodsSpecification?.goods?.warehouseUnit}` }}
                      </template>

                      <template #COUNT="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        <en-input-number v-model="row.count" class="w-full"></en-input-number>
                      </template>

                      <template #BATCH_NO="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        <select-maintain
                          v-model="row.batch"
                          :options="row.goodsSpecification?.goods?.batches.length ? row.goodsSpecification?.goods?.batches : row.goods?.batches"
                          :props="{ label: 'batchNo', value: '' }"
                          value-key="id"
                        ></select-maintain>
                      </template>

                      <template #INVENTORY="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ `${row.batch?.count}${row.goodsSpecification?.name}` }}
                      </template>

                      <template #WAREHOUSE_SHELF="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.batch?.warehouseShelf }}
                      </template>

                      <template #PRIMARY_VEHICLE_SPECS="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.primaryVehicleSpec?.join(',') }}
                      </template>

                      <template #GOODS_TYPE="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.type?.message }}
                      </template>

                      <template #GOODS_BRAND="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        {{ row.goodsSpecification?.goods?.brand }}
                      </template>

                      <template #COMMENT="{ row }: { row: EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto'] }">
                        <en-input v-model="row.comment"></en-input>
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <inventory-requestion-internal-dialog v-model="dialog.visible"></inventory-requestion-internal-dialog>

  <inventory-requestion-internal-detail
    v-model="detail.visible"
    :data="form.data"
    :code="detail.code"
    @confirm="detail.confirm"
  ></inventory-requestion-internal-detail>
</template>

<script lang="ts">
import InventoryRequestionInternalDialog from '@accessory/components/inventory-requestion-internal-dialog.vue'
import InventoryRequestionInternalDetail from '@accessory/components/inventory-requestion-internal-detail.vue'

const formDataGoodsInit = (props: Partial<EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto']>) => {
  return Object.assign({ primeCost: 0 }, props) as EnocloudInventoryDefinitions['MaterialRequisitionGoodsDto']
}

export default factory({
  components: { InventoryRequestionInternalDetail, InventoryRequestionInternalDialog },

  config: {
    children: {
      operation: {
        add: {
          async command(option: 'MRQ' | 'MRQR') {
            await this.dirtyCheck('form')
            this.form.init()
            this.detail.code = option
            this.detail.visible = true
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
              case 'delete':
                await this.form.delete()
                this.form.init()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudServiceDefinitions['ServiceDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/material/requisition/:requisitionId',
            data: 'object',
            loading: true,
            init: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/material/requisition',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          delete: {
            action: 'DELETE /enocloud/material/requisition/:requisitionId',
            loading: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        rules: {},
        computed: {
          disabled() {
            return this.form.data.status?.code === 'A'
          },
          summary() {
            return {
              materialAmount: 0,
              materialCost: 0
            }
          }
        },
        children: {
          materialRequisitionGoods: {
            operation: {
              change(value: EnocloudCommonDefinitions['GoodsDto']) {
                this.form.data.materialRequisitionGoods ??= []

                const { specifications = [], batches } = value

                const goodsSpecification = Object.assign({}, specifications[0], {
                  goods: value
                }) as EnocloudInventoryDefinitions['GoodsSpecificationDto']

                const init = formDataGoodsInit({ goodsSpecification, count: 1 })

                this.form.data.materialRequisitionGoods.push(init)
              },
              delete: {
                click(index: number) {
                  this.form.data.materialRequisitionGoods?.splice(index, 1)
                }
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        code: 'MRQ' as 'MRQ' | 'MRQR',
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      }
    }
  }
})
</script>
